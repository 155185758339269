import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  studentBlogsLink,
  studentGuidesLink,
  useCountryContext,
} from '../../../../contexts/countryContext'

import './styles.scss'

import Link from '../../../atoms/Link'
import LazyImage from '../../../atoms/LazyImage'

import Facebook from '../icons/facebook.svg'
import Twitter from '../icons/twitter.svg'
import Instagram from '../icons/instagram.svg'
import Tiktok from '../icons/tiktok.svg'
import Youtube from '../icons/youtube.svg'

const currentYear = new Date().getFullYear()

const FooterInternational = ({ className, extraLink, variant, ...props }) => {
  const { linkBase, country } = useCountryContext()

  const footerClasses = classNames('site-footer', className, {
    'site-footer--dark': variant === 'dark',
    'site-footer--gray': variant === 'gray',
    'site-footer--warm-gray': variant === 'warm-gray',
  })

  return (
    <footer
      className={footerClasses}
      {...props}
      data-testid="footer-international"
    >
      <div className="site-footer-international__inner">
        <div className="site-footer-international__flex">
          <nav className="site-footer-international__nav site-footer-international__nav--public">
            <ul className="site-footer-international__nav__list">
              <li className="site-footer-international__nav__item">
                <Link
                  className="site-footer-international__nav__link"
                  to={`/about-us?country=${country}`}
                >
                  About us
                </Link>
              </li>
              <li className="site-footer-international__nav__item">
                <Link
                  className="site-footer-international__nav__link"
                  to={`${linkBase}/contact-us`}
                >
                  Contact us
                </Link>
              </li>
              <li className="site-footer-international__nav__item">
                <Link
                  className="site-footer-international__nav__link"
                  to={`/reviews?country=${country}`}
                >
                  Reviews
                </Link>
              </li>
            </ul>
            <ul className="site-footer-international__nav__list">
              <li className="site-footer-international__nav__item">
                <Link
                  className="site-footer-international__nav__link"
                  to={studentBlogsLink()}
                >
                  Student blog
                </Link>
              </li>
              <li className="site-footer-international__nav__item">
                <Link
                  className="site-footer-international__nav__link"
                  to={studentGuidesLink()}
                >
                  Student guides
                </Link>
              </li>
              <li className="site-footer-international__nav__item">
                <Link
                  className="site-footer-international__nav__link"
                  to={`${linkBase}/universities`}
                >
                  Universities
                </Link>
              </li>
              <li className="site-footer-international__nav__item">
                <Link
                  className="site-footer-international__nav__link"
                  to={`${linkBase}/advertise`}
                >
                  Advertise
                </Link>
              </li>
            </ul>
          </nav>
          <nav className="site-footer-international__nav site-footer-international__nav--business">
            <ul className="site-footer-international__nav__list">
              <li className="site-footer-international__nav__item">
                <Link
                  className="site-footer-international__nav__link"
                  to={`/terms?country=${country}`}
                >
                  Terms and Conditions
                </Link>
              </li>
              <li className="site-footer-international__nav__item">
                <Link
                  className="site-footer-international__nav__link"
                  href={`/privacy?country=${country}`}
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
            {extraLink && (
              <ul className="site-footer-international__nav__list">
                {extraLink}
              </ul>
            )}
          </nav>

          <nav className="site-footer-international__nav site-footer-international__nav--social">
            <ul className="site-footer-international__nav__list">
              <li className="site-footer-international__nav__item site-footer-international__nav__item--inline">
                <Link
                  className="site-footer-international__nav__link"
                  href="https://www.facebook.com/accommodation4students"
                  target="_blank"
                  rel="noopener"
                >
                  <span className="u-hidden-visually">Find us on Facebook</span>
                  <Facebook className="site-footer-international__nav__icon" />
                </Link>
              </li>
              <li className="site-footer-international__nav__item site-footer-international__nav__item--inline">
                <Link
                  className="site-footer-international__nav__link"
                  href="https://www.instagram.com/accommodation4students/ "
                  target="_blank"
                  rel="noopener"
                >
                  <span className="u-hidden-visually">
                    Follow us on Instagram
                  </span>
                  <Instagram className="site-footer-international__nav__icon" />
                </Link>
              </li>
              <li className="site-footer-international__nav__item site-footer-international__nav__item--inline">
                <Link
                  className="site-footer-international__nav__link"
                  href="https://twitter.com/accomforstudent"
                  target="_blank"
                  rel="noopener"
                >
                  <span className="u-hidden-visually">Tweet us on Twitter</span>
                  <Twitter className="site-footer-international__nav__icon" />
                </Link>
              </li>
              <li className="site-footer-international__nav__item site-footer-international__nav__item--inline">
                <Link
                  className="site-footer-international__nav__link"
                  href="https://www.tiktok.com/@accommodationforstudents"
                  target="_blank"
                  rel="noopener"
                >
                  <span className="u-hidden-visually">Follow us on TikTok</span>
                  <Tiktok className="site-footer-international__nav__icon" />
                </Link>
              </li>{' '}
              <li className="site-footer-international__nav__item site-footer-international__nav__item--inline">
                <Link
                  className="site-footer-international__nav__link"
                  href="https://www.youtube.com/@accomforstudents"
                  target="_blank"
                  rel="noopener"
                >
                  <span className="u-hidden-visually">Watch us on Youtube</span>
                  <Youtube className="site-footer-international__nav__icon" />
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="site-footer-international__bottom">
          <small className="site-footer-international__copy">
            {`© ${currentYear} Accommodation for Students`}
          </small>
          <div className="site-footer-international__student-minds">
            <span className="student-minds__support-text">
              We proudly support{' '}
            </span>
            <Link
              href="https://www.studentminds.org.uk/"
              target="_blank"
              rel="noopener nofollow"
            >
              <span className="u-hidden-visually">Student Minds</span>
              <LazyImage
                alt="Student Minds logo"
                dataSrc="https://afs-assets-public.s3.eu-west-2.amazonaws.com/charity/student-minds-logo.png"
              />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

FooterInternational.propTypes = {
  className: PropTypes.string,
  extraLink: PropTypes.node,
  variant: PropTypes.oneOf(['dark', 'gray', 'warm-gray']),
}

export default FooterInternational
